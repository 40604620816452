<template>
	<div class="contont-title">
		<slot></slot>
	</div>
</template>

<script>
	export default{
		setup(){
			return
		}
	}
</script>

<style lang="scss" scoped>
	.contont-title{
		padding: 15px 20px;
		text-align: left;
		border-bottom: 1px solid #eeeeee;
		font-size: 16px;
		font-weight: 700;
	}
</style>
