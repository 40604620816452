<template>
  <div>
    <div class="header-action">
      <div class="search-input">
        <slot></slot>
        <div
          class="input-container"
          v-if="filterConfig.search && filterConfig.search.action"
        >
          <el-input
            :placeholder="filterConfig.search.placeholderText"
            v-model="keyword"
            @keyup.enter="emitBtn(filterConfig.search, 'search')"
          >
            <template #suffix>
              <i
                class="iconfont icon-a-lianhe76 search-icon"
                @click="emitBtn(filterConfig.search, 'search')"
              ></i>
            </template>
          </el-input>
        </div>
        <template v-if="filterConfig.selects">
          <div
            class="select-container"
            v-for="(select, s) in filterConfig.selects"
            :key="s"
          >
            <el-select
              v-if="select.type === 'select'"
              :key="select.action"
              v-model="select.value"
              :placeholder="select.lable"
              :clearable="select.clearable == 'no' ? false : true"
              :filterable="select.filterable"
              @change="changeSelect(select)"
              :disabled="select.disabled"
            >
              <el-option
                v-for="(val, key) in select.mapData"
                :key="key"
                :label="select.props ? val[select.props.label] : val"
                :value="select.props ? val[select.props.value] : key"
              ></el-option>
            </el-select>
            <el-date-picker
              v-else-if="select.type === 'daterange'"
              class="daterange"
              v-model="dateArr"
              type="daterange"
              range-separator="至"
              clearable
              :start-placeholder="select.startPlaceholder"
              :end-placeholder="select.endPlaceholder"
              @change="changeDateRangeSelect(select)"
            >
            </el-date-picker>
            <el-select
              v-else-if="select.type === 'remote'"
              :key="select.action"
              v-model="select.value"
              filterable
              remote
              :placeholder="select.lable"
              :remote-method="select.method"
              :clearable="true"
            >
              <el-option
                v-for="(item, index) in select.mapData"
                :key="index"
                :class="select.optionClass[0] || ''"
                :label="item.labels[0]"
                :value="item.value"
              >
                <template v-if="item.labels && item.labels.length">
                  <span
                    v-for="(item2, index2) in item.labels"
                    :key="index2"
                    :class="select.optionClass[index2 + 1] || ''"
                  >
                    {{ item2 }}
                  </span>
                </template>
              </el-option>
            </el-select>
          </div>
        </template>

        <template v-if="filterConfig.group">
          <div
            class="select-container"
            v-for="(group, s) in filterConfig.group"
            :key="s"
          >
            <el-dropdown trigger="click">
              <el-input disabled placeholder="组合查询" />
              <template #dropdown>
                <el-dropdown-menu>
                  <div class="padding">
                    <div
                      class="flex padding border-bottom"
                      v-for="(item, index) in group.childs"
                      :key="index"
                    >
                      <div class="btn">{{ item.lable }}</div>
                      <div class="flex padding-left-xl">
                        <div
                          v-for="(it, i) in item.child"
                          @click="checkGroup(item, it)"
                          class="padding-left-xl btn"
                          style="cursor: pointer"
                          :class="
                            it.id == item.active ? 'theme-text-bg-color' : ''
                          "
                          :key="i"
                        >
                          {{ it.lable }}
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-end padding">
                      <div
                        class="btn flex-center"
                        style="cursor: pointer"
                        @click="cancle(group.childs)"
                      >
                        重置条件
                      </div>
                      <el-dropdown-item>
                        <div
                          class="btn text-theme-color"
                          style="cursor: pointer"
                          @click="changeSelect(group, group.childs)"
                        >
                          设置筛选
                        </div>
                      </el-dropdown-item>
                    </div>
                  </div>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </template>
      </div>
      <div class="btn-action">
        <template v-if="filterConfig.btns">
          <el-button
            v-for="(item, index) in filterConfig.btns"
            :key="index"
            @click="emitBtn(item)"
            size="small"
          >
            <i class="iconfont" :class="item.icon ? item.icon : ''"></i>
            {{ item.lable }}
          </el-button>
        </template>
        <template v-if="filterConfig.icons">
          <div class="action-icon">
            <template v-for="(icon, i) in filterConfig.icons" :key="i">
              <span @click="emitBtn(icon)" class="border-icon">
                <el-tooltip :content="icon.title" placement="left">
                  <i class="iconfont" :class="icon.icon ? icon.icon : ''"></i>
                </el-tooltip>
              </span>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, nextTick } from "vue";
import dayjs from "dayjs";
export default {
  props: ["filterConfig"],
  setup(prop, { emit }) {
    // const { ctx: _this } = getCurrentInstance();
    const organizeName = ref("");
    const keyword = ref("");
    const emitBtn = (item, type) => {
      if (type == "search") {
        emit(item.action, keyword.value);
      } else {
        emit(item.action);
      }
    };

    const changeSelect = (data) => {
      emit(data.action, data);
      // _this?.$forceUpdate();
    };

    const dateArr = ref([]);
    const changeDateRangeSelect = (data) => {
      let lastData = [];
      if (dateArr.value && dateArr.value.length) {
        lastData = [
          dayjs(dateArr.value[0]).format("YYYY-MM-DD"),
          dayjs(dateArr.value[1]).format("YYYY-MM-DD"),
        ];
      }

      emit(data.action, lastData);
      // _this?.$forceUpdate();
    };

    /**
     * 组合筛选
     * */
    const checkGroup = (g, c) => {
      nextTick(() => {
        //写入操作
        g.active = c.id;
        // _this?.$forceUpdate();
      });
    };
    /**
     *重置
     * */
    const cancle = (items) => {
      items.forEach((res) => {
        res.active = 1;
      });
      // _this?.$forceUpdate();
    };
    return {
      cancle,
      checkGroup,
      changeSelect,
      keyword,
      organizeName,
      emitBtn,
      dateArr,
      changeDateRangeSelect,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 80px;
  text-align: center;
  padding: 5px;
}
.el-input.is-disabled .el-input__inner {
  cursor: pointer;
}
</style>
