<template>
  <div class="card-bill-container">
    <ContentTitle>IC卡台账</ContentTitle>
    <Wfilter
      :filterConfig="filterConfig"
      @openCardBillDialog="openCardBillDialog"
      @openCardBillRecordDialog="openCardBillRecordDialog"
      @handleSearch="handleSearch"
      @changeProject="changeProject"
    ></Wfilter>
    <Wtable
      :columns="columns"
      :showPagin="showPagin"
      :pagination="pagination"
      :tableData="tableData"
      @currentChange="currentChange"
    ></Wtable>
    <Wdialog
      ref="cardBillDialog"
      title="新增领卡记录"
      width="30%"
      @wConfirm="handleAddCardBill"
    >
      <el-form
        ref="cardBillFormRef"
        :model="cardBillFormData"
        :rules="cardBillFormRules"
        label-position="top"
        class="card-bill-form"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item prop="recipient" label="领用人员">
              <el-select
                v-model="cardBillFormData.recipient"
                placeholder="请选择领用人员"
              >
                <el-option
                  v-for="item in recipientOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item prop="receiveTime" label="领用时间">
              <el-date-picker
                v-model="cardBillFormData.receiveTime"
                type="date"
                placeholder="选择领用时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="receiveNumber" label="领用数量">
              <el-input
                type="number"
                min="0"
                v-model="cardBillFormData.receiveNumber"
                autocomplete="off"
                placeholder="请输入领用数量"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Wdialog>
    <Wdialog
      ref="cardBillRecordDialog"
      title="领卡记录"
      width="60%"
      :showAction="false"
    >
      <Wtable
        :columns="recordColumns"
        :showPagin="recordShowPagin"
        :pagination="recordPagination"
        :tableData="recordTableData"
        @currentChange="recordCurrentChange"
      ></Wtable>
    </Wdialog>
  </div>
</template>
<script>
import { ref } from "vue";
import Wtable from "@/components/wTable/wTable.vue";
import Wdialog from "@/components/wDialog/wDialog.vue";
import Wfilter from "@/components/wFilter/wFilter.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import config from "./cardBill.json";

export default {
  components: {
    Wtable,
    Wdialog,
    Wfilter,
    ContentTitle,
  },
  setup() {
    const filterConfig = config.filterConfig;
    const columns = config.tableConfig.list;
    const showPagin = config.tableConfig.showPagin;
    const tableData = ref([
      {
        project: "一个大项目",
        customer: "苏文纨",
        roomName: "2003号",
        cardId: "SED004",
        cardNumber: 20,
        money: 77,
        operator: "苏渐渐",
        sellTime: "2021/10/09",
      },
    ]);
    const recipientOptions = ref([
      {
        value: "选项1",
        label: "领用人1",
      },
      {
        value: "选项2",
        label: "双皮奶",
      },
    ]);
    const pagination = ref({ total: 9 });
    const currentChange = (page) => {
      console.log(page);
    };
    const changeProject = (pro) => {
      console.log("pro==", pro);
    };
    const handleSearch = (kwd) => {
      console.log("===>", kwd);
    };
    const cardBillDialog = ref(null);
    const cardBillFormRef = ref(null);
    const cardBillFormRules = {
      recipient: [
        { required: true, message: "请选择领用人员", trigger: "change" },
      ],

      receiveTime: [
        { required: true, message: "请选择领用时间", trigger: "change" },
      ],

      receiveNumber: [
        { required: true, message: "请输入领用数量", trigger: "blur" },
      ],
    };
    const cardBillFormData = ref({
      recipient: "",
      receiveTime: "",
      receiveNumber: "",
    });
    function openCardBillDialog() {
      cardBillFormData.value = {
        recipient: "",
        receiveTime: "",
        receiveNumber: "",
      };
      cardBillDialog.value.show();
    }
    function handleAddCardBill() {
      if (cardBillFormRef.value) {
        cardBillFormRef.value.validate((valid) => {
          if (valid) {
            cardBillDialog.value.close();
          }
        });
      }
    }

    const cardBillRecordDialog = ref(null);
    function openCardBillRecordDialog() {
      cardBillRecordDialog.value.show();
    }
    const recordColumns = config.recordTableConfig.list;
    const recordShowPagin = config.recordTableConfig.showPagin;
    const recordPagination = ref({ total: 9 });
    const recordTableData = ref([
      {
        receiptCardNumber: 77,
        recipient: "苏渐渐",
        receiptCardTime: "2021/09/09",
      },
      {
        receiptCardNumber: 77,
        recipient: "苏渐渐",
        receiptCardTime: "2021/09/09",
      },
      {
        receiptCardNumber: 77,
        recipient: "苏渐渐",
        receiptCardTime: "2021/09/09",
      },
    ]);
    const recordCurrentChange = (page) => {
      console.log(page);
    };
    return {
      tableData,
      columns,
      showPagin,
      pagination,
      currentChange,
      cardBillDialog,
      handleAddCardBill,
      cardBillFormData,
      cardBillFormRules,
      cardBillFormRef,
      openCardBillDialog,
      recipientOptions,
      filterConfig,
      changeProject,
      handleSearch,
      cardBillRecordDialog,
      openCardBillRecordDialog,
      recordColumns,
      recordShowPagin,
      recordPagination,
      recordTableData,
      recordCurrentChange,
    };
  },
};
</script>

<style lang='scss'>
.card-bill-container {
  .header-action {
    .el-input__inner {
      background-color: var(--search-bg-color) !important;
    }
    ::-webkit-input-placeholder {
      color: var(--theme-color);
    }
  }
  .card-bill-form {
    padding-bottom: 80px;
  }
}
</style>